<template>
    <div class="animated fadeIn">

      <!-- THIS MODAL IS TO SEE ALL THE INVOICES -->
      <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
        <b-card>
          <rotate-square2 id="loading-gif" v-show="loadingInvoiceData"></rotate-square2>
          <div class="row" v-if="!loadingInvoiceData" >
          <h4><i class="fal fa-file-chart-line"></i> All Invoices</h4>
          <hr>
            <div class="col-md-12">          
              <v-client-table :data="masterInvoiceModalData" id="rt-master-invoice-table" :columns="masterInvoiceModalColumns" :options="masterInvoiceModalOptions" :theme="theme">                 
                <div slot="invoiceNumber" slot-scope="props">
                  {{props.row.invnum}}      
                </div>
                <div slot="submissionDate" slot-scope="props">
                  {{props.row.creation_date}}
                </div>      
                <div slot="totalWithoutGst" slot-scope="props">
                  ${{formatPrice(Math.round(props.row.total * 100) / 100)}}
                </div>    
                <div slot="totalWithGst" slot-scope="props">
                  ${{formatPrice(Math.round((props.row.total + (props.row.total  * (props.row.tax_percentage / 100))) * 100) / 100)}}
                </div>                    
                <div slot="totalWithDiscount" slot-scope="props">
                  ${{formatPrice(Math.round((props.row.total + (props.row.total  * (props.row.discount / 100))) * 100) / 100)}}
                </div>    
                <div slot="status" slot-scope="props">
                  <InvoiceStatusManager :invoice="props.row"></InvoiceStatusManager>
                </div>   
                <div slot="view" slot-scope="props">
                <button class="btn-sm btn-blue" v-if="props.row.inv_passed == 13 && authority == 1 || props.row.inv_passed == 23 && authority <= 2 || props.row.inv_passed == 33 && authority <= 3 || props.row.inv_passed == 43 && authority <= 4 || props.row.accountant_paid == 1 || props.row.inv_passed == 11 || props.row.inv_passed == 21 || props.row.inv_passed == 31 || props.row.inv_passed == 41 || props.row.inv_passed == 51 || props.row.inv_passed == 7 || props.row.inv_passed == 14 || props.row.inv_passed == 24 || props.row.inv_passed == 34 || props.row.inv_passed == 44 || props.row.inv_passed == 17 || props.row.inv_passed == 54" @click="setInvoice(props.row, 0)" v-b-modal.invoiceModal>Open invoice</button>
                <button class="btn-sm btn-blue" v-else @click="setInvoice(props.row, 1)" v-b-modal.invoiceModal>Open invoice</button>   
                </div>                                
              </v-client-table>      
            </div>
          </div> 
        </b-card>        
      </b-modal>

      <!-- THIS MODAL IS FOR INVESTIGATION TABLE -->
      <b-modal id="investigate-modal" ref="investigate-modal" class="modal-dialog" hide-header ok-only>
          <div class="col-md-12">          
              <AccountantInvestigationTable v-bind:filterSelection="filterSelection"> </AccountantInvestigationTable>
          </div>      
      </b-modal>

      <b-card>
        <h4><i class="fal fa-file-chart-line"></i> Master Invoice</h4>
        <hr>
        <div class="row my-3">
          <div class="col-md-4">
            <h5>Select Project(s)</h5>
            <multiselect class="align-with-afes" @input="onProjectOptionChange()" v-model="projectSelection" track-by="projectid" :options="listOfProjects" :multiple="true" :close-on-select="true" label="projectname"></multiselect>
          </div>
          <div class="col-md-4" v-if="listOfAfes.length > 0">
            <h5>Select AFE(s) <button @click="onFilterAfes()" class="btn btn-info btn-sm mx-2"><i class="fas fa-filter"></i> Filter AFEs</button>  <button @click="onFilterChange(true)" class="btn btn-danger btn-sm float"><i class="fas fa-eraser"></i> Clear Filters</button> </h5>
            <multiselect @input="onSelectAfe()" v-model="afeSelection" :options="listOfAfes" track-by="afenum" :multiple="true" :close-on-select="false" label="afenum"></multiselect>
          </div>
          <div class="col-md-4" v-if="afeSelection.length > 0 && listOfAfes.length > 0">
            <h5>Select Vendor</h5>
            <multiselect @input="onVendorSelect()" :multiple="false"  class="mt-3 mb-3" v-model="vendorSelection" track-by="vid" :options="listOfVendors" label="vcn">
              <template slot="option" slot-scope="props">
                <div class="option__desc"><span class="option__title">{{props.option.vcn}} ({{props.option.vlname}})</span></div>
              </template>
            </multiselect>
          </div>
          <div class="col-md-4" v-show="displayNotAfeFoundNotification">
            <h5 style="color:red">Warning</h5>
            No Afe's found for the selected filtering criteria.
             <button @click="onFilterChange(true)" class="btn btn-danger btn-sm float mt-3"><i class="fas fa-eraser"></i> Clear Filters</button>
          </div>
          <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>     
        </div>

          <div class="row my-1" v-if="displayAfeFilters">
            <div class="col-md-12">
              <h5>Filter AFE(s)</h5>
            </div>
            <div class="col-md-4">
              <h6>By Category</h6>
              <multiselect @input="onFilterChange(false)" track-by="afenum" :multiple="true"  v-model="categoryFilterSelection" placeholder="Category" :close-on-select="true" :options="listOfCategories" label="category" ></multiselect>
            </div>
            <div class="col-md-4">
              <h6>By Well</h6>
              <multiselect @input="onFilterChange(false)" track-by="afenum" :multiple="true"   v-model="wellFilterSelection" :options="listOfUwis" label="uwi" ></multiselect>
            </div>
          </div>

        <div class="row" v-show="displayBuildInvoiceButton && listOfAfes.length > 0">
          <div class="col-md-12">
            <hr>
          </div>
        </div>

        <div class="row mt-3" v-show="displayBuildInvoiceButton && listOfAfes.length > 0">
          <div class="col-md-4">
            <h5>Filter By Date (optional) <button @click="clearDates()" class="btn btn-info btn-sm float float-right clear-dates"><i class="fas fa-eraser"></i> Clear Dates</button> </h5>
            <v-date-picker
              class="vc-calendar date-padding"
              :formats='formats'
              mode='range'
              v-model='dateFilterRange'
              show-caps>
            </v-date-picker>
          </div>
          <div class="col-md-8">
            <h5>&nbsp;</h5>
            <button @click="loadInvoices(true)" v-b-modal.master-invoice-modal class="btn btn-primary align-button mr-3"><i class="fas fa-analytics"></i> View all invoices</button>
            <button @click="loadInvoices(false)" class="btn btn-primary align-button"><i class="fas fa-chart-bar"></i> Generate list of all sub-invoices</button>
          </div>
        </div>
      </b-card>

      <b-card v-if="displayInvoicesTable">
        <!--  <InvoiceHeader v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:serviceData="serviceData" v-bind:afes="afes" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name"></InvoiceHeader> -->

       <!--   CUSTOMER / VENDOR INFORMATION  -->
      <b-row class="mt-3 clearfix mb-4">
        <b-col md="4">
          <VendorDetails v-bind:invoice="invoice"></VendorDetails>
        </b-col>
        <b-col md="4">
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="mother_company_name"></CompanyDetails>
        </b-col>
          <b-col md="4">
          <div>
            <h4 class="mb-3">Details</span> </h4>
            <hr>
            <ul style="list-style: none;" class="fa-ul">
              <li v-for="afe in groupProjects" style="margin-bottom: 1em;">
                <span class="fa-li"><i class="fa fa-project-diagram"></i></span>{{afe.name}}

                <ul v-for="item in afe.info" class="fa-ul" style="margin-left:1.5em; margin-bottom: 0.25em;">
                  <li>
                    <span class="fa-li"><i class="fa fa-tint"></i></span>UWI: {{item.uwi}}
                  </li>
                  <li>
                    <span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{item.ln}}
                  </li>
                  <li>
                    <span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{item.lsdsurface}}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        <!-- <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="mother_company_name"></CompanyDetails> -->
        </b-col>
        </b-row>
      </b-card>
      
      <b-card v-if="displayInvoicesTable">
        <h4><i class="fas fa-sticky-note"></i> Sub Invoice Details</h4>
        <hr>
        <div class="row">
          <div class="col-md-9">
            <table class="table table-clear total-table">
              <tbody>
                <tr>
                  <td class="left"><strong>Subtotal</strong></td>
                  <td class="right">${{formatPrice(subtotal)}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>GST ({{invoice.tax_percentage}}%)</strong></td>
                  <td class="right">${{tax}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Total</strong></td>
                  <td class="right"><strong>${{formatPrice(total)}}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-3 text-center">
            <button class="btn-lg btn-blue p-3 m-3"  @click="viewInvestigationTable()" v-b-modal.investigate-modal>Investigate More</button>
          </div>
        </div>

        <v-client-table :data="tableDataInvoices" id="rt-master-invoice-table" :columns="columnsInvoices" :options="options" :theme="theme">
          <template slot="child_row" slot-scope='props'>
            <v-client-table class="rt-table-striped rt-master-child-table" :columns="childColumnsInvoices" :options="childOptions" :theme="theme">
              <div slot="pu" slot-scope="props">
                ${{props.row.pu}}/{{props.row.uom}}
              
              </div>
              <div slot="quantity" slot-scope="props">
                {{props.row.quantity}}
              </div>
              <div slot="discount" slot-scope="props">
                {{formatPrice(props.row.discount)}}%
              </div>
              <div slot="total" slot-scope="props">
                ${{formatPrice((props.row.quantity * props.row.pu) - ((props.row.discount/100) * props.row.quantity * props.row.pu))}}
              </div>
              <div slot="percentage" slot-scope="props">
                {{formatPrice(props.row.percentage)}}%
              </div>
              <div slot="inv_passed" slot-scope="props">
                <InvoiceStatusManager :invoice="props.row.invoice"></InvoiceStatusManager>
              </div>
              <div slot="view" slot-scope="props">
                <button class="btn-sm btn-blue" v-if="props.row.inv_passed == 13 && authority == 1 || props.row.inv_passed == 23 && authority <= 2 || props.row.inv_passed == 33 && authority <= 3 || props.row.inv_passed == 43 && authority <= 4 || props.row.accountant_paid == 1 || props.row.inv_passed == 11 || props.row.inv_passed == 21 || props.row.inv_passed == 31 || props.row.inv_passed == 41 || props.row.inv_passed == 51 || props.row.inv_passed == 7 || props.row.inv_passed == 14 || props.row.inv_passed == 17 || props.row.inv_passed == 24 || props.row.inv_passed == 34 || props.row.inv_passed == 44 || props.row.inv_passed == 54" @click="setInvoice(props.row.invoice, 1)" v-b-modal.invoiceModal>Open invoice</button>
                <button class="btn-sm btn-blue" v-else @click="setInvoice(props.row.invoice, 0)" v-b-modal.invoiceModal>Open invoice</button>
              </div>
            </v-client-table>


          </template>
        </v-client-table>

        <!-- THIS MODAL IS FOR THE SINGLE INVOICE  -->
        <b-modal id="invoiceModal" ref="invoiceModal" class="invoice-modal" hide-header ok-only>
          <ManagerInvoice master @onStatusUpdate="updateInvoiceStatus"></ManagerInvoice>
        </b-modal>     

        
      </b-card>
    </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'
import Datepicker from 'vuejs-datepicker';

import InvoiceHeader from './../invoice/InvoiceHeader'
import VendorDetails from './../invoice/InvoiceVendorDetails'
import CompanyDetails from './../invoice/InvoiceCompanyDetails'
import InvoiceDetails from './../invoice/InvoiceDetails'
import InvoiceTotals from './../invoice/InvoiceTotals'
import ManagerInvoice from './../manager/ManagerInvoice'
import InvoiceStatusManager from './../../components/InvoiceStatusManager'
import AccountantInvestigationTable from './AccountantInvestigationTable'

/* import { loadavg } from 'os'; */
/* import { lookup } from 'dns'; */

Vue.use(ClientTable)

export default {
  name: 'AccountantMasterInvoice',
  components: {
    ClientTable,
    Event,
    Multiselect,
    RotateSquare2,
    Datepicker,
    VendorDetails,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    ManagerInvoice,
    InvoiceStatusManager,
    AccountantInvestigationTable,
  },
  props : ['propinvoice','propafes'],
  data () {
    return {
      
      //  ----------------- Table config for masterInvoiceModalData
    
        masterInvoiceModalData: [],
        masterInvoiceModalColumns : ['invoiceNumber','submissionDate','totalWithoutGst', 'totalWithGst', 'totalWithDiscount', 'status','view'],
        masterInvoiceModalOptions: { headings : { invoiceNumber : "#", submissionDate : "Submission Date", totalWithoutGst : "Total Without Gst", totalWithGst : "Total With GST", totalWithDiscount : "Total With Discount", status : "Status", "view" : "View"  },
                                    filterable: ['invoiceNumber', 'submissionDate', 'totalWithoutGst', 'totalWithGst', 'totalWithDiscount','status'],
                                    sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
                                    sortable : []
                                    },
      //  -----------------
        columnsInvoices: ['name', 'type', 'uom', 'pu', 'quantity','discount','totalwithdiscount','totalwithoutdiscount'],
        options: {
          headings: {
            name: 'Service Name',
            type: 'Service Code',
            uom: 'UOM',
            pu: 'Price/Unit',
            quantity : 'Quantity',
            discount : 'Discount',
            totalwithdiscount : 'Total With Discount',
            totalwithoutdiscount : 'Total without Discount',
          },
          filterable: ['name', 'type', 'uom', 'pu', 'quantity','discount','totalwithdiscount','totalwithoutdiscount'],
          sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        },
        childColumnsInvoices: ['pu',	'quantity', 'discount',	'total',	'afenum',	'percentage',	'start_date',	'inv_passed',	'view'],
        childOptions: {
          headings: {
            pu: 'Price/Unit',
            quantity: 'Quantity',
            discount: 'Discount',
            total: 'Total',
            afenum : 'AFE',
            percentage : 'Percentage',
            creation_date : 'Submission Date',
            inv_passed : 'Status',
            view : 'View Invoice',
          },
          filterable: false,
          sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        },
        theme: 'bootstrap4',
        template: 'default',

        //  for Projects, AFE and UWI dropdowns
        listOfProjects: [],
        listOfAfes: [],
        listOfUwis: [],  //List of Unique Well Identifier
        listOfInvoices: [],
        listOfCategories: [],
        listOfVendors: [],
        listOfInvoiceServices:[],
        listOfInitalAfes:[],

        afeSelection: [],
        wellFilterSelection: null,
        categoryFilterSelection: null,
        uwiSelection: [],
        projectSelection: [],
        vendorSelection: null,

        displayBuildInvoiceButton: false,
        displayInvoicesTable: false,
        displayAfeFilters:false,
        displayNotAfeFoundNotification:false,

        loadingInvoiceData:false,

        filterSelection:null, //for investigation table

        invoice:[],   //  For the invoice header/company details/etc components
        total:0,
        subtotal:0,
        tax:0,
        tableDataInvoices: [],
        loading: false,
        mother_company_name:'',

        //Datepicker
        formats: {
          title: 'MMMM YYYY',
          weekdays: 'W',
          navMonths: 'MMM',
          input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'], // Only for `v-date-picker`
          dayPopover: 'L', // Only for `v-date-picker`
        },
        dateFilterRange:null

    }
  },
  methods: {
    loadData() {
        //  Get all the projects under this WTS (PM)
        this.$axios.get('manager/get/projects').then(response => {
          this.listOfProjects = response.data.result;
        })
        .catch(error => {});
    },
    viewInvestigationTable(){
      this.filterSelection = {};
      this.filterSelection.xAxisSelection = 'Afe';
      this.filterSelection.yAxisSelection = 'Line Item';
      this.filterSelection.dataSelection = 'Total With Discount';
    },
    async updateInvoiceStatus(invnum){
        let newListOfInvoices = [];
        console.log("The invoice number was " + invnum);
        //  Processed invoices
        const processedRes = await this.$axios.get('/manager/get/processed_invoices/')
        .then(response => {
            let invoice = response.data.result;
          console.log("#1 got processed invoices");
          console.dir(invoice);
            if(invoice.length > 0){
                console.log('got ' + invoice.length + " processed invoices");
                newListOfInvoices = newListOfInvoices.concat(invoice);
            }
        }).catch(error => {})

        //  Unprocessed invoices
        const unprocessedRes = await this.$axios.get('/manager/get/unprocessed_invoices/')
        .then(response => {
            console.log("#2 got unprocessed invoices");
            let invoice = response.data.result;
            console.dir(invoice);
            if(invoice.length > 0){
                console.log('got ' + invoice.length + " unprocessed invoices");
                newListOfInvoices = newListOfInvoices.concat(invoice);
            }
        }).catch(error => {});
        //debugger;
        console.log("new list of invoices before unqiueby");
        console.dir(newListOfInvoices);
        newListOfInvoices = this.lodash.uniqBy(newListOfInvoices,'invnum');

        var refreshedInvoice = newListOfInvoices.find(invoice => {
            return invoice.invnum === invnum
        });


        console.log("refreshed invoice (should be new invoice)");
        console.dir(refreshedInvoice.inv_passed);
        console.log('this is the matching invoice from the old model:');
        console.log(this.masterInvoiceModalData.find(invoice => {
        return invoice.invnum === invnum;
        }).inv_passed);

        this.masterInvoiceModalData.find(invoice => {
        return invoice.invnum === invnum;
        }).inv_passed = refreshedInvoice.inv_passed;
        this.$refs.invoiceModal.hide();

        /* console.log('#3');
        console.log(this.listOfInvoiceServices);
        this.listOfInvoiceServices.find(invoice => {
        return invoice.invoice.invnum === invnum;
        }).invoice.inv_passed = refreshedInvoice.inv_passed; */
        
    },
    clearModel(){
        //  Clear model
        this.listOfInvoices = [];
        this.listOfVendors = [];
        this.listOfCategories = [];
        this.listOfUwis = [];
        this.listOfAfes = [];
        this.listOfInvoiceServices = [];
        this.tableDataInvoices = [];
    },
    //  When the user selects a project get all the AEFs for this project
    onProjectOptionChange(){
      if(this.projectSelection != null && this.projectSelection.length <1){
        this.displayInvoicesTable = false;
        this.clearModel();
      }
      if(this.projectSelection != null){
        this.clearModel();
        this.afeSelection = [];
        //  Re-Init model
        this.projectSelection.forEach(project => {
          this.getDataFromProject(project.projectid);
        })
      }
    },
    //  Retrive the invoice based off project id (both processed and unprocessed)
    //  Concat them to the "listOfInvoices" data model
    //  Also get all the vendors and add to listOfVendors data model
    async getDataFromProject(projectid){
      console.log('#1 building invoices for project id ' + projectid);
      //  Processed invoices
      const processedRes = await this.$axios.get('/manager/get/master_invoice/')
      .then(response => {
          let invoice = response.data.result;
          console.log('found invoices');
          console.dir(invoice);
          if(invoice.length > 0 && !this.listOfInvoices.includes(invoice)){
            console.log('got ' + invoice.length + " processed invoices");
            this.listOfInvoices = this.listOfInvoices.concat(invoice);
          }
      }).catch(error => {})

      //  Unprocessed invoices
      /* const unprocessedRes = await this.$axios.get('/manager/get/unprocessed_invoices/')
      .then(response => {
          let invoice = response.data.result;
          console.dir(invoice);
          if(invoice.length > 0 && !this.listOfInvoices.includes(invoice)){
            console.log('got ' + invoice.length + " unprocessed invoices");
            this.listOfInvoices = this.listOfInvoices.concat(invoice);
          }
      }).catch(error => {}); */

      this.listOfInvoices = this.lodash.uniqBy(this.listOfInvoices,'invnum');

      if(this.listOfInvoices.length < 1){
        this.$toasted.show('Error. ' + 'No invoices found for the project selection(s)', {type: 'error', duration: '3000'});
        return;
      }

      console.log('list of invoices:');
      console.dir(this.listOfInvoices);
      console.log('getting invoice services');
      this.loading = true;
      for(var i = 0 ; i < this.listOfInvoices.length ; i++){
        let invoice = this.listOfInvoices[i];
        await this.$axios.get('/manager/get/invoice_services/'+projectid+'/'+invoice.invoiceid)
        .then(response => {
          console.log('getting invoice services for invoice ' +invoice.invoiceid);
          this.listOfInvoiceServices = this.listOfInvoiceServices.concat(response.data.result);
        }).catch(error => {})
      }
      this.loading = false;

      console.log('Creating unique lists based off list of invoice services');
      console.dir(this.listOfInvoiceServices)

      this.listOfAfes = this.listOfAfes.concat(this.listOfInvoiceServices);
      this.listOfAfes = this.lodash.uniqBy(this.listOfAfes,'afenum');
      this.listOfInitalAfes = this.listOfAfes;

      //
      //  Grab mother company name off an AFE
      //

      if(this.listOfAfes.length < 1){
        this.$toasted.show('Error. ' + 'No invoices found for the project selection(s)', {type: 'error', duration: '3000'});
        return;
      }

      this.mother_company_name = this.listOfAfes[0].company_name;

      /* this.listOfVendors = this.listOfVendors.concat(this.listOfInvoiceServices);
      this.listOfVendors = this.lodash.uniqBy(this.listOfInvoiceServices,'vid'); */

      console.log('Now generating well and category info based off list of AFEs');
      console.dir(this.listOfAfes);
      this.listOfCategories = this.listOfCategories.concat(this.listOfAfes);
      this.listOfCategories = this.lodash.uniqBy(this.listOfAfes,'category');

      /* this.getWells(); */
      this.listOfUwis = this.listOfUwis.concat(this.listOfAfes);
      this.listOfUwis = this.lodash.uniqBy(this.listOfAfes,'uwi');
      console.log('list of uwis');
      console.dir(this.listOfUwis)
    },

    async buildVendorList(){
        this.listOfVendors = this.lodash.uniqBy(this.listOfInvoices,'vid');
        console.log("list of vendors:");
        console.log(this.listOfVendors);
    },
      //  Generate a list of vendors based on the selected AFE
    //  Do this by first sorting thru the list of invoiceServices
    async onSelectAfe(){
      if(this.afeSelection.length > 0){
        this.buildVendorList();
      }
    },
    //  Toggle view filters
    onFilterAfes(){
      this.displayAfeFilters = !this.displayAfeFilters;
    },
    async getWells(){
      const wellsResp = await this.$axios.get('/wts/get/wells/' + this.projectSelection.projectid)
        .then(response => {
          this.listOfUwis = this.lodash.uniqBy(this.listOfUwis.concat(response.data,result),'uwi');
          /* this.listOfCategories = this.lodash.uniqBy(this.listOfCategories.concat(response.data,result),'category'); */
          console.log('list of wells');
          console.log(this.listOfUwis);
        }).catch(error => {
           this.$toasted.show('Error. ' + error.message , {type: 'error', duration: '3000'})
        });
    },

    onVendorSelect(){
      if(this.vendorSelection != null){
        this.displayBuildInvoiceButton = true;
      } else {
        this.displayBuildInvoiceButton = false;
      }

    },
    //Called when the user selects a filtering option
    //if the selection is undefined, restore the old AEF list
    onFilterChange(clear){

      //  Clear filters
      if(clear){
        this.categoryFilterSelection = null;
        this.wellFilterSelection = null;
      }

      //  Restore inital list of AFEs
      this.listOfAfes = this.listOfInitalAfes;

      this.displayNotAfeFoundNotification = false;

      let hasCategoryFilter = this.categoryFilterSelection != null && this.categoryFilterSelection.length > 0;
      let hasWellFilter = this.wellFilterSelection != null && this.wellFilterSelection.length > 0;
      let afesFilteredByCategory = [];
      let afesFilteredByWell = [];

      //  Filter by category
      if(hasCategoryFilter){
        this.categoryFilterSelection.forEach(filterChoice => {
          afesFilteredByCategory = afesFilteredByCategory.concat(this.listOfAfes.filter( afe => {
            return afe.category ===  filterChoice.category;
          }));
        })
        console.log('===Filtering by category===');
        console.log('found ' + afesFilteredByCategory.length + ' afes');
        afesFilteredByCategory.forEach(afe => {
          console.log(afe.afenum);
        });
        console.log("\n");
      }

      //  Filter by well
      if(hasWellFilter){
        this.wellFilterSelection.forEach(filterChoice => {
          afesFilteredByWell = afesFilteredByWell.concat(this.listOfAfes.filter( afe => {
            return afe.uwi ===  filterChoice.uwi;
          }));
        })
        console.log('===Filtering by well===');
        console.log('found ' + afesFilteredByWell.length + ' afes');
        afesFilteredByWell.forEach(afe => {
          console.log(afe.afenum);
        });
        console.log("\n");
      }

      //  Both filters
      if(hasWellFilter && hasCategoryFilter){
        console.log("===both filters===");
        console.log("lodash");
        console.dir(this.lodash.intersection(afesFilteredByCategory,afesFilteredByWell));
        this.listOfAfes = this.lodash.intersection(afesFilteredByCategory,afesFilteredByWell);
      //  Only well filter
      } else if (hasWellFilter && !hasCategoryFilter){
        this.listOfAfes = afesFilteredByWell;
      //  Only category filter
      } else if (!hasWellFilter && hasCategoryFilter) {
        this.listOfAfes = afesFilteredByCategory;
      }

      if(this.listOfAfes.length < 1){
        this.displayNotAfeFoundNotification = true;
      }

      console.log("\n\n\n");
    },

    clearDates(){
      this.dateFilterRange = null;
    },
    //  displayMasterInvoiceModal : boolean
    //  specifies wether or not to show the "group invoice modal"
    loadInvoices(displayMasterinvoiceModal){

      this.displayInvoicesTable = false;
      this.tableDataInvoices = [];
      this.loadingInvoiceData = true;
      this.subtotal = 0;
      this.tax = 0;
      this.gst = 0;

      if(this.afeSelection.length < 1 || this.projectSelection.length < 1 || this.vendorSelection < 1){
        this.$toasted.show('Error. ' + 'You must select a project, an AFE and a vendor', {type: 'error', duration: '3000'})
      } else {

        //
        //  First, filter all the table data
        //

        //  Filter all the line items to be within the selected AFEs
        let tentativeTableData = [];
        console.log("afe selection count: " + this.afeSelection.length);
        this.afeSelection.forEach(afe => {
          tentativeTableData = tentativeTableData.concat(this.listOfInvoiceServices.filter(line => {
            return line.afenum === afe.afenum;
          }));
        })

        console.log("inital tentativeTableData (service line items");
        console.dir(tentativeTableData);

        //  Filter based on vendor selection (make sure line items are only based on vendor selection)
        tentativeTableData = tentativeTableData.filter( line => {
          console.log(this.vendorSelection);
            return line.vid === this.vendorSelection.vid;
        });

        //  Filter based on date selection
        if(this.dateFilterRange != null){
          //  Mangle the data around for comparisons easier...
          // subtract and add days are to make filter "inclusive" from what they selected
          let startDate = this.$moment(this.dateFilterRange.start).subtract('1','day').format('YYYY-MM-DD');
          let endDate = this.$moment(this.dateFilterRange.end).add('1','day').format('YYYY-MM-DD');
          tentativeTableData = tentativeTableData.filter( line => {
            return this.$moment(line.start_date).isAfter(startDate) &&
                    this.$moment(line.end_date).isBefore(endDate);
          });
        }

        //  Filter based on rejected status
        tentativeTableData = tentativeTableData.filter( line => {
          return line.inv_passed != 9 
                  && line.inv_passed != 5
                  && line.inv_passed != 14
                  && line.inv_passed != 24
                  && line.inv_passed != 34
                  && line.inv_passed != 44
                  && line.inv_passed != 54
                  && line.inv_passed != 17               
        });

        //
        //  Let them know the filter removed everything from the list of items
        //
        if(tentativeTableData.length < 1){
          this.$toasted.show('Error. ' + 'There were no line items within the selected criteria. Please make sure Cost Centers are properly configured.', {type: 'error', duration: '5000'});
          this.loadingInvoiceData = false;
          this.masterInvoiceModalData = [];
          this.$refs.master-invoice-modal.hide();
          return;
        }

        //
        //  For each service line, attach the actual invoice its associated with to a param "invoice" (hacky, but whatever...)
        //

        tentativeTableData.forEach(serviceLine => {
            serviceLine.invoice = this.listOfInvoices.find( invoice => {
              return invoice.invoiceid === serviceLine.invoiceid;
            });
            console.log('service line with invoice attrib');
            console.dir(serviceLine);
        });

        //
        //  From here, we have our entire list of invoice service items "tentativeTableData"
        //

        //
        //  Set the invoice param to be the first service line (same info across all lines because same vendor)
        //
        this.invoice = tentativeTableData[0].invoice;

        //
        //  Calc subtotal, tax and gst
        //
        tentativeTableData.forEach(serviceLine => {
          this.subtotal += serviceLine.quantity * serviceLine.pu - ((serviceLine.discount/100) * serviceLine.quantity * serviceLine.pu);
        })

        //Add em up
        this.tax = this.subtotal * (this.invoice.tax_percentage/100);
        this.total = this.subtotal + this.tax;

        //Format
        this.tax = this.formatPrice(this.tax);

        //  Map the invoices data to a 2d array
        //  array[{services, name}], [services]
        //  1 dimention is "header" row
        //  2 dimention is the data rows onces header row is expanded
        const that = this;
        that.tableDataInvoices = that.lodash.chain(tentativeTableData)
        .groupBy('name')
        .toPairs()
        .map(function(currentItem) {
          return that.lodash.zipObject(['name', 'services'], currentItem) })
        .value();

        //
        //  build the entire data model for the table based on this 2d array model
        //
        //
        let i = 0;
        this.tableDataInvoices.forEach(tableData => {
          tableData.id = i++;
          tableData.type = tableData.services[0].type;
          tableData.uom = tableData.services[0].uom;
          if(this.lodash.uniqBy(tableData.services, 'pu').length > 1){
            tableData.pu = "Mixed UOM";
          }
          else {
            tableData.pu = "$" + tableData.services[0].pu + "/" + tableData.services[0].uom;
          }

          let quantity = 0;
          let discountSumLine = 0;
          let sumLine = 0;

          tableData.services.forEach(serviceEntry => {
            quantity += serviceEntry.quantity;
            //serviceEntry.discount = Math.round(Math.random() * (20 - 5) + 5); //for testing discounts
            discountSumLine += ((serviceEntry.quantity * serviceEntry.pu) * (100-serviceEntry.discount) / 100);
            sumLine += (serviceEntry.quantity * serviceEntry.pu);
          });

          tableData.quantity = quantity;
          //tableData.discount = 100 - (discountSumLine / );

          tableData.totalwithoutdiscount = sumLine;
          //tableData.totalwithdiscount =  ((tableData.totalwithoutdiscount) * ((100-tableData.discount)/100));
          tableData.totalwithdiscount =  discountSumLine;

          tableData.discount = (((tableData.totalwithoutdiscount - tableData.totalwithdiscount) / tableData.totalwithoutdiscount) * 100);
        })

        //  Go back into the tabledata one more time to fix up some formatting

        this.tableDataInvoices.forEach(tableData => {
            tableData.discount = (Math.round(tableData.discount * 100) / 100) + '%';
            tableData.totalwithdiscount = '$' + (Math.round(tableData.totalwithdiscount * 100) / 100);
            tableData.totalwithoutdiscount = '$' + (Math.round(tableData.totalwithoutdiscount * 100) / 100);
            tableData.pu = tableData.pu
        });

        console.log('table data invoices');
        console.dir(this.tableDataInvoices);
        this.loadingInvoiceData = false;

        if(displayMasterinvoiceModal){
          this.masterInvoiceModalData = this.listOfInvoices;
          this.masterInvoiceModalData = this.masterInvoiceModalData.filter( invoice => {
            return invoice.vid === this.vendorSelection.vid;
          });
          this.masterInvoiceModalData.forEach(invoice => {
            console.log(invoice);
            if(typeof invoice.creation_date_modified === 'undefined'){
              invoice.creation_date = this.$moment(invoice.creation_date).format('MMMM Do YYYY'); //format the date
              invoice.creation_date_modified = true;
            }      
          });
        }

        this.displayInvoicesTable = true;         
      }
    },
  },
  computed: {
    groupProjects: function(){
      const that = this;
      var unique = that.lodash.uniqBy(that.afeSelection,  v => [v.uwi, v.projectname].join());

      var grouped = that.lodash.chain(unique)
      .groupBy('projectname')
      .toPairs()
      .map(function(currentItem) {
        return that.lodash.zipObject(['name', 'info'], currentItem) })
      .value();

      return grouped;
    }
  },
  mounted: async function () {
    this.loadData();
    if(typeof this.propafes === 'undefined'){
      return;
    }
    //  Get a list of all the projects and make the selectedproject based on the ID
    const getprojselection = await this.$axios.get('manager/get/projects').then(response => {
      this.projectSelection = response.data.result.filter( o => {
        return o.projectid === this.propinvoice.projectid
      });
    })
    .catch(error => {});
    const getproj = await this.getDataFromProject(this.propinvoice.projectid);
    this.afeSelection = this.propafes;
    const getvendors = await this.buildVendorList();
    this.vendorSelection = this.listOfVendors.filter( o => {
      return o.vid === this.propinvoice.vid;
    })[0];
    this.loadInvoices(false);
    this.displayBuildInvoiceButton = true;
  }
}
</script>

<style lang="scss">
  #rt-master-invoice-table div table thead tr th {
    background-color: #444;
    color: #fff;
    font-size: 1.2em;
  }
  #rt-master-invoice-table div table thead tr th:first-child {
    width: 50px;
  }
  #rt-master-invoice-table td {
    vertical-align: middle;
  }
  #rt-master-invoice-table .VueTables__child-row td {
    padding-right: 0 !important;
  }

  .rt-master-child-table{
    tbody {
      background-color: rgba(63, 233, 38, 0.09);
    }
    margin-left: 40px;

    .VuePagination {
      margin-top: 2em;
    }

    th {
      background: #7d7d7d !important;
      color: #fff !important;
      font-size: 1em !important;
    }
    th {
      width: inherit !important;
    }

    .VueTables__child-row > td {
      padding: 0;
    }
  }

  .view-button {
    min-width: auto !important;
    max-width: inherit !important;
    width: auto !important;
    color: #fff;
  }

  #invoiceModal {
    .modal-dialog {
      max-width: 1400px !important;
    }
  }

  #master-invoice-modal {
    .modal-dialog {
      max-width: 1400px !important;
    }
  }

  #investigate-modal {
      .modal-dialog {
      max-width: 1400px !important;
    }  
  }

  .align-with-afes {
    margin-top: 16px;
  }

  #loading-gif{
    margin-top: 40px;
    margin-left: 40px;
  }

  .clear-dates {
    margin-bottom: 10px;
  }

  .align-button {
    margin-top: 10px;
  }

  .VueTables__child-row-toggler--open {
    line-height: 17px !important;
  }

  #rt-master-invoice-table {
    .parent th {
      background: #444;
      color: #fff;
    }

    .checkslots {
      text-align: center;
      margin: 0 auto;
      display: block;
      margin-top: 2px;
      font-size: 4em;
    }
    .table td {
      padding: 0.55rem;
    }
    .VueTables__heading {
      text-align: left;
    }
    .VuePagination {
      text-align: center;
      justify-content: center;
    }

    .VueTables__search-field {
      display: flex;
    }
    .VueTables__search-field input {
      margin-left: 0.25rem;
    }

    .VueTables__limit-field {
      display: flex;
    }

    .VueTables__limit-field select {
      margin-left: 0.25rem !important;
    }

    .VueTables__table th {
      text-align: left;
    }

    .VueTables__child-row-toggler {
      width: 16px;
      height: 16px;
      line-height: 18px;
      background: #4dbd74;
      display: block;
      margin: auto;
      text-align: center;
      padding: 6px !important;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
    }

    .VueTables__child-row-toggler--closed::before {
      content: "\f078" !important;
      font-weight: 400 !important;
      font-family: "Font Awesome 5 Pro" !important;
    }

    .VueTables__child-row-toggler--open::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400 !important;
      content: "\f077";
    }
  }
  .date-padding input {
    padding: 8px !important;
  }
</style>
